/*
-For global css, edit in glocals.css
  
Main Color
Blue    #0077AB, #3392BC, #66ADCD, #CCE4EE
Orange  #F39800, #F5AC33, #F8C166, #FDEACC
purple  #923F93, #A865A9, #BE8CBE, #E9D9E9
Gray    #000000, #333333, #666666, #CCCCCC
*/

html,
body {
  padding: 0;
  margin:0;
  /* margin: 0 auto 0 auto; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: #0077AB;
  text-decoration: none;
}

a:hover, a:active { color:#3392BC; text-decoration: none; }

* {
  box-sizing: border-box;
}

.wrapper {
  margin:20px;
}

.viewport {
  max-width: 1200px;
  margin:0 auto 0 auto;
}

#navBtn{
  color: #666666;
}

#navBtn:hover{
  color:#000000;
}

.carousel-indicators li{
  margin-bottom: -18px;
}

#post_api_content img{
  max-width:100%;
}
.navbar-toggler{
  border-color:rgba(0, 0, 0, 0.2) !important;
}
@media screen and (max-width: 600px) {
  .carousel-indicators li{
    margin-bottom: -23px;
  }
}

@media screen and (max-width: 900px) {

}

@media screen and (width: 1024px) and (height: 1366px) {

}

.remark {
  color: #0000ff;
}
